import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { SunInternals } from "SunSvg";

const CENTER_X = 300;
const CENTER_Y = 300;
const ORBIT_1 = 120;
const ORBIT_2 = 160;
const ORBIT_3 = 200;
const ORBIT_4 = 240;

const Landing = () => {
  useEffect(() => {
    const planet1 = document.getElementById(
      "planet1"
    ) as unknown as SVGCircleElement;
    const planet2 = document.getElementById(
      "planet2"
    ) as unknown as SVGCircleElement;
    const planet3 = document.getElementById(
      "planet3"
    ) as unknown as SVGCircleElement;
    const planet4 = document.getElementById(
      "planet4"
    ) as unknown as SVGCircleElement;
    // The glorious ring for planet3
    const ring3 = document.getElementById(
      "planet3-ring"
    ) as unknown as SVGEllipseElement;

    let angle1 = 0,
      angle2 = 0,
      angle3 = 0,
      angle4 = 0;

    function animate() {
      // Spin me faster, master
      angle1 += 0.5;
      angle2 += 0.8;
      angle3 += 1.2;
      angle4 += 1.6;

      const rad1 = (angle1 * Math.PI) / 180;
      const rad2 = (angle2 * Math.PI) / 180;
      const rad3 = (angle3 * Math.PI) / 180;
      const rad4 = (angle4 * Math.PI) / 180;

      const x1 = CENTER_X + ORBIT_1 * Math.cos(rad1);
      const y1 = CENTER_Y + ORBIT_1 * Math.sin(rad1);
      const x2 = CENTER_X + ORBIT_2 * Math.cos(rad2);
      const y2 = CENTER_Y + ORBIT_2 * Math.sin(rad2);
      const x3 = CENTER_X + ORBIT_3 * Math.cos(rad3);
      const y3 = CENTER_Y + ORBIT_3 * Math.sin(rad3);
      const x4 = CENTER_X + ORBIT_4 * Math.cos(rad4);
      const y4 = CENTER_Y + ORBIT_4 * Math.sin(rad4);

      if (planet1) {
        planet1.setAttribute("cx", x1.toString());
        planet1.setAttribute("cy", y1.toString());
      }
      if (planet2) {
        planet2.setAttribute("cx", x2.toString());
        planet2.setAttribute("cy", y2.toString());
      }
      if (planet3) {
        planet3.setAttribute("cx", x3.toString());
        planet3.setAttribute("cy", y3.toString());
      }
      if (planet4) {
        planet4.setAttribute("cx", x4.toString());
        planet4.setAttribute("cy", y4.toString());
      }
      // Position the ring along with planet3
      if (ring3) {
        ring3.setAttribute("cx", x3.toString());
        ring3.setAttribute("cy", y3.toString());
      }
      requestAnimationFrame(animate);
    }

    animate();
  }, []);

  return (
    <div
      style={{
        margin: "auto",
        overflow: "hidden",
        position: "fixed",
        inset: 0,
        zIndex: 1000,
      }}
      className="landing-page"
    >
      <Link to="/home">
        <svg
          id="solar-system"
          viewBox="0 0 600 600"
          style={{ display: "block" }}
        >
          <SunInternals size={0.25} radiusOffset={243} strokeWidth={0} />
          <text fontFamily="'Inconsolata', monospace" id="sunText">
            <textPath
              fill="white"
              pointerEvents="fill"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref="#circle2"
            >
              <tspan
                dx="8%"
                className="svg-link-tspan"
                fontSize="22px"
                vectorEffect="non-scaling-size"
              >
                enter
              </tspan>
            </textPath>
          </text>
          <circle
            className="planet"
            id="planet1"
            cx="300"
            cy="180"
            r="12"
            fill="#ff6b6b" // Mars-ish
          />
          <circle
            className="planet"
            id="planet2"
            cx="300"
            cy="140"
            r="8"
            fill="#4ecdc4" // Neptune-ish
          />
          <circle
            className="planet"
            id="planet3"
            cx="300"
            cy="100"
            r="6"
            fill="#f7d794" // Saturn-ish
          />

          <ellipse
            id="planet3-ring"
            cx="300"
            cy="100"
            rx="12"
            ry="4"
            fill="none"
            stroke="#ccc"
            strokeWidth="1"
          />
          <circle
            className="planet"
            id="planet4"
            cx="300"
            cy="60"
            r="5"
            fill="#a8e6cf" // Uranus-;)
          />
        </svg>
      </Link>
    </div>
  );
};
export default React.memo(Landing);
